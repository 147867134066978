.custom-simplebar {
  background-color: #3f1e81;
  color: #fff;
  font-size: 12px;
  height: auto; 
  line-height: 10px !important;
  /* overflow-y: auto; */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #6a349f #3f1e81; /* For Firefox */
}

/* For WebKit browsers (Chrome, Safari) */
.custom-simplebar::-webkit-scrollbar {
  width: 0px;
}

.custom-simplebar::-webkit-scrollbar-thumb {
  background-color: #6a349f;
}

.custom-simplebar::-webkit-scrollbar-track {
  background-color: #3f1e81;
}

.custom-simplebar::-cui-backdrop{
 background-color: #ffffff;
}
