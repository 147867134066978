.no-wrap-cell {
  white-space: nowrap;
  overflow: hidden;
  background-color: red;
  text-overflow: ellipsis; /* You can use "clip" instead of "ellipsis" if you prefer cutting off the text without an ellipsis */
}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader--sortable{
  background-color: #3F1E81;
  color:whitesmoke;
}
p{
  /* margin-top: 0px; */
  margin-bottom: 1px;
}
.white-background {
  background-color: white;
}

.full-width-button {
  width: 100%;
}
